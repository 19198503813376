@font-face {
    font-family: 'BacktoBlack';
    src:    url("../fonts/BacktoBlack.otf") format("opentype"),
            url("../fonts/BacktoBlack.ttf") format("truetype");
}
@font-face {
    font-family: 'Saira_Extra_Condensed';
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Black.ttf') format('TrueType');
    }
@font-face {
    font-family: 'Saira_Extra_Condensed';
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-ExtraBold.ttf') format('TrueType');
    }
@font-face {
    font-family: 'Saira_Extra_Condensed';
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Thin.ttf') format('TrueType');
    }
@font-face {
    font-family: 'Saira_Extra_Condensed';
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.ttf') format('TrueType');
    }
    @font-face {
        font-family: 'Saira_Extra_Condensed';
        font-style: normal;
        font-weight: 400;
        font-stretch: normal;
        src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.ttf') format('TrueType');
        }

@font-face {
    font-family: 'Bebas_Neue';
    src:    url("../fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("TrueType");
}